<template>
    <div>
        <el-container>
            <el-aside width="200px">
            <common-aside/>
            </el-aside>
            <el-container>
                <el-header>
                    <common-header/>
                </el-header>
                <common-Tag/>
                <el-main>
                    <router-view></router-view>
                </el-main>
            </el-container>
        </el-container>

    </div>


</template>

<script>
import CommonAside from '../components/CommonAside.vue'
import CommonHeader from '../components/CommonHeader.vue'
import CommonTag from '../components/CommonTag.vue'
export default {
    components:{
        CommonAside,
        CommonHeader,
        CommonTag
    }
 
}
</script>

<style lang="less" scoped>
.el-header{
    padding: 0;
}
</style>