import Vue from "vue";
import Vuex from "vuex";
import tab from "../store/tab";
Vue.use(Vuex)

// 1: 创建VueX 实例

const store = new Vuex.Store({
    modules: {
        tab,
    },
  })
  
  export default store
  