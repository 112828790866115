<template>
    <el-menu default-active="1-4-1" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose"
        background-color="#8895ee" text-color="#fff" active-text-color="#eafc53">
        <div class="logo-view" style="">
            <img src="../assets/login_logo.jpg" class="logo-image" />
            <div style="margin-top: 10px;">哈喽便捷签</div>
        </div>
        <el-menu-item @click="clickMenu(item)" v-for="item in noChildren" :key="item.name" :index="item.name">
            <span slot="title">{{ item.label }}</span>
        </el-menu-item>
        <el-submenu v-for="item in hasChildren" :key="item.label" :index="item.label">
            <template slot="title">
                <span slot="title">{{ item.label }}</span>
            </template>
            <el-menu-item-group v-for="subItem in item.children" :key="subItem.path">
                <el-menu-item :index="subItem.path" @click="clickMenu(subItem)">{{ subItem.label }}</el-menu-item>
            </el-menu-item-group>
        </el-submenu>
    </el-menu>
</template>

<script>
import { getInfo } from "../api";
import Cokie from 'js-cookie'
export default {
    data() {
        return {
            ArrMenu: [
                {
                    path: "/",
                    name: "contract",
                    label: "我的合同",
                },
                {
                    path: "/user",
                    name: "user",
                    label: "我的客户",
                },
                {
                    label: "系统管理",
                    children: [
                        {
                            path: "/Company",
                            name: "Company",
                            label: "公司管理",
                        },
                        {
                            path: "/Admin",
                            name: "Admin",
                            label: "管理员管理",
                        },
                    ],
                },
            ],
            menuData: [

            ],
            deptId: ""
        };
    },
    mounted() {
        this.getInfo();
        // if (!Cokie.get('deptId')) {
          
        // } else {
        //     this.Getmenu(Cokie.get('deptId'))
        // }

    },
    methods: {
        handleOpen(key, keyPath) {
            console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            console.log(key, keyPath);
        },
        clickMenu(e) {
            console.log("---跳转页面");
            //  跳转页面
            //  当前页面的路由与跳转路由不一致才允许跳转
            if (
                this.$route.path !== e.path && !(this.$route.path === "/home" && e.path === "/")
            ) {
                this.$router.push(e.path);
            }
            this.$store.commit('selectMenu', e)
        },
        getInfo() {
            getInfo().then((data) => {
                const dict = data.data;
                if (dict.code === 200) {
                    this.deptId = dict.user.deptId;
                    Cokie.set('deptId', this.deptId)
                    this.Getmenu(this.deptId)
                } else {
                    this.$message.error(dict.msg);
                }
            });
        },
        Getmenu(deptId) {
            console.log('deptId=============' + deptId);
            if (deptId != '100') {
                const lastElement = this.ArrMenu[this.ArrMenu.length - 1];
                lastElement.children.splice(lastElement.children.length[0], 1);
                this.menuData = this.ArrMenu;
                return;
            } else {
                this.menuData = this.ArrMenu;
                return;
            }
        }
    },
    computed: {
        //  没有子菜单
        noChildren() {
            return this.menuData.filter((item) => !item.children);
        },
        //  有子菜单
        hasChildren() {
            return this.menuData.filter((item) => item.children);
        },
    },
};
</script>
<style lang="less" scoped>
.el-menu {
    height: 100vh;

    .logo-view {
        width: 200px;
        height: 140px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 30px;
        color: white;

        .logo-image {
            width: 95px;
            height: 90px;
            border-radius: 50px;
        }
    }

    .el-menu-item {
        height: 70px;
    }

    .menu-label {
        height: 80px;
        color: white;
        line-height: 80px;
    }

}

// 修改展开颜色
::v-deep .el-submenu__title {
    .el-icon-arrow-down:before {
        background-size: 100%;
        color: white;
    }
}
</style>