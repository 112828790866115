import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store';
import Cokie from 'js-cookie'
import {  handleTree,resetForm } from "./utils/tool";
Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.prototype.handleTree = handleTree
Vue.prototype.resetForm = resetForm
// 添加全局前置导航守卫
router.beforeEach((to, from, next) => {
  //判断token 是否存在
  const tooken =  Cokie.get('token')
  // token 不存在用户未登录
  if(!tooken && to.name !=='login'){
    next({name:'login'})

  }else if (tooken && to.name ==='login'){// 如果token 存在 说明用户登录 跳转至首页
    next({name:'contract'})
  }else{
    next()
  }
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
