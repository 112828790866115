<template>
  <div>
    <!-- 搜索框 -->
    <div style="display: flex; justify-content: space-between; height: 40px">
      <el-form ref="form" :model="queryParams" :inline="true">
        <el-form-item label="公司名称">
          <el-select v-model="queryParams.deptId">
            <el-option v-for="(item, index) of companyList" :key="index" :label="item.value" :value="item.key"
              :disabled="item.key === 1">{{ item.value }}</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用户名称">
          <el-input placeholder="用户名称" v-model="queryParams.userName"></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="queryParams.status">
            <el-option label="正常" value="0"></el-option>
            <el-option label="停用" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search " @click="GetUserList">搜索</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="success" icon="el-icon-plus" @click="handleAdd">新增</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 列表 -->
    <el-card style="margin-top: 40px">
      <el-table :data="tableData" stripe border style="width: 100%" :header-cell-style="headerCellStyle"
        :cell-style="cellStyle">
        <el-table-column prop="userId" label="用户编号" width="180" fixed="left">
        </el-table-column>
        <el-table-column prop="dept.deptName" label="公司名称" width="180">
        </el-table-column>
        <el-table-column prop="userName" label="用户名称">
        </el-table-column>
        <el-table-column prop="nickName" label="用户昵称">
        </el-table-column>
        <el-table-column prop="phonenumber" label="手机号码">
        </el-table-column>
        <el-table-column prop="status" label="状态">
          <template scope="scope">
            <span>{{ scope.row.status == '0' ? '正常' : '停用' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间">
        </el-table-column>
        <el-table-column label="操作" align="center" class-name="small-padding fixed-width" fixed="right">
          <template slot-scope="scope" v-if="scope.row.userId !== 1">
            <el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(scope.row)">修改</el-button>
            <el-button size="mini" type="text" icon="el-icon-plus" @click="handleAdd(scope.row)">新增</el-button>
            <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <!-- 弹窗 -->
    <!-- 添加或修改用户配置对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="600px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="用户昵称" prop="nickName">
              <el-input v-model="form.nickName" placeholder="请输入用户昵称" maxlength="30" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="归属部门" prop="deptId">
              <el-select v-model="form.deptId" class="input-view-plus">
            <el-option v-for="(item, index) of deptArr" :key="index" :label="item.deptName" :value="item.deptId"
              :disabled="item.key === 1">{{ item.deptName }}</el-option>
          </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="用户名称" prop="userName">
              <el-input v-model="form.userName" placeholder="请输入用户名称" maxlength="30" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="手机号码" prop="phonenumber">
              <el-input v-model="form.phonenumber" placeholder="请输入手机号码" maxlength="11" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item v-if="form.userId == undefined" label="用户密码" prop="password">
              <el-input v-model="form.password" placeholder="请输入用户密码" type="password" maxlength="20" show-password />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="备注">
              <el-input v-model="form.remark" type="textarea" placeholder="请输入内容"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="状态">
              <el-radio-group v-model="form.status">
                <el-radio label="0">正常</el-radio>
                <el-radio label="1">停用</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { userList, getUser, deptTreeSelect, getUserData, updateUser, addUser, delUser,userDeptById } from "../api";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  components: { Treeselect },
  data() {
    return {
      deptArr:[],
      queryParams: {
        deptId: undefined,
        userName: undefined,
        status: undefined,
        pageNum: 1,
        pageSize: 10,

      },
      // 选中数组
      ids: [],
      tableData: [],
      companyList: [],
      // 部门树选项
      deptOptions: undefined,
      // 岗位选项
      postOptions: [],
      // 表单参数
      form: {},
      // 角色选项
      roleOptions: [],
      open: false,
      title: '',
      // 表单校验
      rules: {
        userName: [
          { required: true, message: "用户名称不能为空1", trigger: "blur" },
          { min: 2, max: 20, message: '用户名称长度必须介于 2 和 20 之间', trigger: 'blur' }
        ],
        nickName: [
          { required: true, message: "用户昵称不能为空", trigger: "blur" }
        ],
        password: [
          { required: true, message: "用户密码不能为空", trigger: "blur" },
          { min: 5, max: 20, message: '用户密码长度必须介于 5 和 20 之间', trigger: 'blur' },
          { pattern: /^[^<>"'|\\]+$/, message: "不能包含非法字符：< > \" ' \\\ |", trigger: "blur" }
        ],
        email: [
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"]
          }
        ],
        phonenumber: [
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur"
          }
        ]
      }


    };
  },

  mounted() {
    this.GetUserList()
    this.getDeptTree()
    this.userDeptById()

  },

  methods: {
    handleUpdate(row) {
      this.reset();
      const userId = row.userId || this.ids;
      getUserData(userId).then(response => {
        this.form = response.data.data;
        console.log(response.data);
        this.postOptions = response.posts;
        this.roleOptions = response.roles;
        this.$set(this.form, "postIds", response.postIds);
        this.$set(this.form, "roleIds", response.roleIds);
        this.open = true;
        this.title = "修改用户";
        this.form.password = "";
      });
    },
    submitForm() {
      if (this.form.deptId == null) {
        this.$message.error('请选择归属部门')
        return;
      }
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.userId != undefined) {
            updateUser(this.form).then(response => {
              this.$message.success("修改成功");
              this.open = false;
              this.GetUserList();
            });
          } else {
            console.log(this.form);
            addUser(this.form).then(response => {
             console.log(response);
              this.$message.success("新增成功");
              this.open = false;
              this.GetUserList();
            });
          }
        }
      });

    },
    /** 查询部门下拉树结构 */
    getDeptTree() {
      deptTreeSelect().then(response => {
        this.deptOptions = response.data.data;
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    /** 用户列表 */
    GetUserList() {
      userList(this.queryParams).then((data) => {
        const dict = data.data;
        var arr = [];
        if (dict.code === 200) {
          this.tableData = dict.rows;
          for (let index = 0; index < this.tableData.length; index++) {
            const element = this.tableData[index];
            console.log(element);
            let dict = { 'key': element.dept.deptId, 'value': element.dept.deptName }
            arr.push(dict);
          }
          if (this.companyList.length == 0) {
            this.companyList = arr;
          }
        } else {
          this.$message.error(dict.msg);
        }
      });
    },

    userDeptById(){
      userDeptById().then(response => {
        this.deptArr = response.data.data
      });
      },
    handleAdd() {
      this.reset();
      getUser().then(response => {
        console.log(response);
        this.postOptions = response.posts;
        this.roleOptions = response.roles;
        this.open = true;
        this.title = "添加管理员";
        this.form.password = this.initPassword;
      });

    },
    //  table 样式
    headerCellStyle() {
      return "background-color: #e8e8e8;text-align: center;color: black;";
    },
    cellStyle() {
      return "text-align: center;";
    },
    // 表单重置
    reset() {
      this.form = {
        userId: undefined,
        deptId: undefined,
        userName: undefined,
        nickName: undefined,
        password: undefined,
        phonenumber: undefined,
        email: undefined,
        sex: undefined,
        status: "0",
        remark: undefined,
        postIds: [],
        roleIds: []
      };
      this.resetForm("form");
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const userIds = row.userId || this.ids;
      this.$confirm('是否确认删除用户编号为"' + userIds + '"的数据项？', "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delUser(userIds).then((data) => {
          if (data.data.code === 200) {
            this.GetUserList();
            this.$message.success("删除成功");
          } else {
            this.$message.error(dict.msg);
          }
        });
      })
        .catch(() => { });

    },

  },
};
</script>