import http from "@/utils/request";
import { parseStrEmpty } from "../utils/tool";
//获取验证码
export const captchaImage = () => {
    return http.get('/captchaImage')
}
// 登录
export const login = (data) => {
    return http.post('/login', data)
}
// 添加合同
export const contract = (data) => {
    return http.post('/system/contract', data)
}
// 合同列表
export const contractList = (params) => {
    return http.get('system/contract/list', {
        params
    })
}
//根据id获取详情
export const modContract = (data) => {
    return http.put('/system/contract', data)
}
//我的客户列表
export const myClientList = (params) => {
    return http.get('/system/contract/myClientList', {
        params
    })
}
//获取用户信息
export const getInfo = () => {
    return http.get('getInfo')
}
// 查询部门列表
export const listDept = (params) => {
    return http.get('/system/dept/list', {
        params
    })
}
// 新增公司
export const systemDept = (data) => {
    return http.post('/system/dept', data)

}
// 查询部门列表（排除节点）
export function listDeptExcludeChild(deptId) {
    return http.get('/system/dept/list/exclude/' + deptId, {
    })
}
// 查询部门详细
export function getDept(deptId) {
    return http.get('/system/dept/' + deptId, {
    })
}

// 修改部门
export function updateDept(data) {
    return http.put('/system/dept', data)
  }

  // 删除部门
export function delDept(deptId) {
    return http.delete('/system/dept/' + deptId, {
    })
  }
  //用户列表
  export function userList(params) {
    return http.get('/system/user/list', {
        params
    })
  }
  // 查询用户详细
export function getUser(userId) {
    return http.get('/system/dept/'  + parseStrEmpty(userId), {
    })
  }
  // 查询部门下拉树结构
export function deptTreeSelect() {
    return http.get('/system/user/deptTree', {
    })
  }
//   
  export function getUserData(userId) {
    return http.get('/system/user/'  + userId, {
    })
  }

  // 修改用户
export const updateUser = (data) => {
    return http.put('/system/user', data)
}


// 新增用户
export const addUser = (data) => {
    return http.post('/system/user', data)

}
//获取用户公司及旗下子公司列表
export const userDeptById = () => {
    return http.get('/system/dept/userDeptById')

}


// 删除用户
export function delUser(userId) {
    return http.delete('/system/user/' + userId, {
    })
  }
  








