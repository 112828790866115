<template>
  <div class="page-view">
    <div style="display: flex; justify-content: space-between; height: 40px">
      <el-form ref="form" :model="queryFrom" :inline="true">
        <el-form-item label="公司名称 ">
          <el-select v-model="queryFrom.deptId" class="input-view-plus">
            <el-option
              v-for="(item, index) of deptArr"
              :key="index"
              :label="item.deptName"
              :value="item.deptId"
              :disabled="item.key === 1"
              >{{ item.deptName }}</el-option
            >
          </el-select>
        </el-form-item>
        <el-form-item label="合同主题">
          <el-input
            placeholder="请输入合同主题"
            class="input-view"
            v-model="queryFrom.contractName"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input
            placeholder="请输入联系手机号"
            class="input-view-plus"
            v-model="queryFrom.phone"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="contractList" icon="el-icon-search"
            >搜索</el-button
          >
        </el-form-item>
      </el-form>
      <el-button type="success" icon="el-icon-plus" @click="handleInitiate"
        >发起签约</el-button
      >
    </div>
    <el-card style="margin-top: 40px">
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        max-height="600"
        :header-cell-style="headerCellStyle"
        v-loading="loading"
        :cell-style="cellStyle"
      >
        <el-table-column
          prop="deptName"
          label="公司名称"
          width="180"
          fixed="left"
        >
        </el-table-column>
        <el-table-column prop="contractName" label="主题" width="180">
        </el-table-column>
        <el-table-column prop="contractNo" label="合同编号" width="150">
        </el-table-column>
        <el-table-column  label="合同状态" width="100" >
          <template slot-scope="scope">
            <span :style="{'color':scope.row.statusColor}"> {{ scope.row.status }}</span>
          </template>
        </el-table-column>
        <el-table-column label="短信投递" width="100">
          <template slot-scope="scope">
            {{ scope.row.smsResult == 0 ? "已发送" : "发送失败" }}
          </template>
        </el-table-column>
        <el-table-column prop="phone" label="联系电话" width="130">
        </el-table-column>
        <el-table-column prop="clientName" label="联系人" width="150">
        </el-table-column>
        <el-table-column prop="createTime" label="发起时间" width="180">
        </el-table-column>
        <el-table-column prop="signTime" label="合同签约时间" width="180">
        </el-table-column>
        <el-table-column label="用车时间" width="250">
          <template slot-scope="scope">
            {{ scope.row.useStartDate }} 至 {{ scope.row.useEndDate }}
          </template>
        </el-table-column>
      
        <el-table-column prop="endNote" label="服务状态" width="300">
        </el-table-column>
        <el-table-column prop="operator" label="创建人" width="100">
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="380px">
          <template slot-scope="scope">
            <el-button
              size="mini"
              @click="QROperation(scope.row)"
              type="warning"
              >分享</el-button
            >
            <el-button
              size="mini"
              @click="handleOperation(scope.row)"
              type="primary"
              >查看</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="cancellation(scope.row, 0)"
              >作废</el-button
            >
            <el-button
              size="mini"
              type="info"
              @click="cancellation(scope.row, 1)"
              >完成任务</el-button
            >
            <el-button
              size="mini"
              type="success"
              @click="handleDownload(scope.row)"
              >下载</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <!-- 分页器 -->
    <el-pagination
      v-show="total > 0"
      background
      layout="prev, pager, next"
      :total="total"
      style="margin-top: 20px"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
    <!-- 弹窗 -->
    <el-dialog
      :title="modalType == 0 ? '发起签约' : Contractinfo.contractName"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
    >
      <el-form
        ref="Contractinfo"
        size="small"
        :label-position="labelPosition"
        label-width="25%"
        :model="Contractinfo"
        :rules="rules"
      >
        <el-form-item label="公司名称">
          <el-select v-model="Contractinfo.deptId" class="input-view-plus">
            <el-option
              v-for="(item, index) of deptArr"
              :key="index"
              :label="item.deptName"
              :value="item.deptId"
              :disabled="item.key === 1"
              >{{ item.deptName }}</el-option
            >
          </el-select>
        </el-form-item>
        <el-form-item label="合同主题" prop="contractName">
          <el-input
            class="input-view-plus"
            v-model="Contractinfo.contractName"
          ></el-input>
        </el-form-item>
        <el-form-item label="合同编号" prop="contractNo">
          <el-input
            class="input-view-plus"
            v-model="Contractinfo.contractNo"
          ></el-input>
        </el-form-item>
        <el-form-item label="客户名称" prop="clientName">
          <el-input
            class="input-view-plus"
            v-model="Contractinfo.clientName"
          ></el-input>
        </el-form-item>
        <el-form-item label="用车联系方式" prop="clientName">
          <el-input
            class="input-view-plus"
            v-model="Contractinfo.phone"
          ></el-input>
          <span style="margin-left: 10px">
            <i class="el-icon-warning-outline"></i>
            <label style="font-size: 13px; margin-left: 2px"
              >此手机号同时用于接受签约短信通知</label
            >
          </span>
        </el-form-item>
        <el-form-item label="车型及数量" prop="carNum">
          <el-input
            class="input-view-plus"
            v-model="Contractinfo.carNum"
          ></el-input>
        </el-form-item>
        <el-form-item label="用车天数" prop="useDay">
          <el-input
            class="input-view-plus"
            v-model="Contractinfo.useDay"
          ></el-input>
        </el-form-item>

        <el-form-item label="用车时间" required>
          <el-col :span="8">
            <el-form-item prop="useStartDate">
              <el-date-picker
                type="date"
                placeholder="选择日期"
                v-model="Contractinfo.useStartDate"
                value-format="yyyy-MM-dd"
                style="width: 100%"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col class="line" :span="2" style="padding: 0px 20px">至</el-col>
          <el-col :span="8">
            <el-form-item prop="useEndDate">
              <el-date-picker
                type="date"
                placeholder="选择日期"
                v-model="Contractinfo.useEndDate"
                value-format="yyyy-MM-dd"
                style="width: 100%"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-form-item>

        <el-form-item label="签订日期" required>
          <el-form-item prop="signTime">
            <el-date-picker
              type="date"
              placeholder="选择日期"
              v-model="Contractinfo.signTime"
              value-format="yyyy-MM-dd"
              class="input-view-plus"
            ></el-date-picker>
          </el-form-item>
        </el-form-item>
        <el-form-item label="接人时间地点" prop="dateOrAddress">
          <el-input
            class="input-view-plus"
            v-model="Contractinfo.dateOrAddress"
          ></el-input>
        </el-form-item>
        <el-form-item label="详情行程线路" prop="pathDetail">
          <el-input
            class="input-view-plus"
            v-model="Contractinfo.pathDetail"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            class="input-view-plus"
            v-model="Contractinfo.note"
          ></el-input>
        </el-form-item>
        <el-form-item label="包车运营费" prop="price">
          <el-input
            class="input-view-plus"
            v-model="Contractinfo.price"
            oninput="value=value.replace(/[^0-9.]/g,'')"
          ></el-input>
        </el-form-item>
        <el-form-item label="付款方式">
          <span>
            <el-input
              placeholder="请输入金额"
              v-model="Contractinfo.prepay"
              style="margin-left: 10px; width: 13vw"
              oninput="value=value.replace(/[^0-9.]/g,'')"
            >
              <label slot="prepend">预付</label>
              <label slot="append">元</label>
            </el-input>
            <el-input
              v-model="Contractinfo.payment"
              placeholder="请输入金额"
              style="margin-left: 10px; width: 13vw"
              oninput="value=value.replace(/[^0-9.]/g,'')"
            >
              <label slot="prepend">现付</label>
              <label slot="append">元</label>
            </el-input>
          </span>
        </el-form-item>
        <el-form-item label="票据说明">
          <el-input
            class="input-view-plus"
            v-model="Contractinfo.invoiceNote"
          ></el-input>
        </el-form-item>
        <el-form-item label="其他" prop="otherPay">
          <el-select
            placeholder="请选择费用承担方"
            v-model="Contractinfo.otherPay"
          >
            <el-option label="甲方" value="1"></el-option>
            <el-option label="乙方" value="2"></el-option>
          </el-select>
          <span
            ><label style="font-size: 13px; margin-left: 2px">
              负责过路过桥费、油费、停车费</label
            ></span
          >
        </el-form-item>
        <el-form-item label="我司商务人员姓名" prop="businessUsername">
          <el-input
            class="input-view-plus"
            v-model="Contractinfo.businessUsername"
          ></el-input>
        </el-form-item>
        <el-form-item label="我司商务人员联系方式" prop="businessPhone">
          <el-input
            class="input-view-plus"
            v-model="Contractinfo.businessPhone"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="handlecommit"
          v-loading.fullscreen.lock="fullscreenLoading"
          v-if="modalType == 0"
          >{{ modalType == 0 ? "确定" : "修 改" }}</el-button
        >
      </span>
    </el-dialog>
    <!-- 完成或者合同提示  -->
    <el-dialog
      :title="titleFinish"
      :visible.sync="dialogFinish"
      width="30%"
      :before-close="finishClose"
    >
      <span>{{
        titleFinish == "作废合同"
          ? "是否作废此合同，此合同作废后，将不再生效，请及时通知用户"
          : "请与司机和商务人员确认服务信息，是否已完成服务？"
      }}</span>
      <el-input
        type="textarea"
        v-model="endNote"
        style="margin-top: 20px"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogFinish = false">取 消</el-button>
        <el-button type="primary" @click="endSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <!--二维码 -->
    <el-dialog
      :visible.sync="QRVisible"
      width="30%"
      :before-close="QRClose"
      title="提示"
      center
    >
      <div
        style="
          width: 100%;
          height: 200px;
          display: flex;
          align-items: center;
          justify-content: center;
        "
      >
        <div
          ref="qrcode"
          style="width: 200px; height: 200px"
          v-if="QRVisible"
        ></div>
      </div>

      <span slot="footer" class="dialog-footer">
        <span>将二维码截图分享至微信好友，好友失败/扫码二维码查看合同</span>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { contract, contractList, modContract, userDeptById } from "../api";
import Cokie from "js-cookie";
import axios from "axios";
import QRCode from "qrcodejs2";
export default {
  data() {
    return {
      value: "https://www.baidu.com/?tn=25017023_17_dg", //二维码内容
      size: 500, //二维码大小
      deptArr: [],
      labelPosition: "right",
      dialogFinish: false,
      dialogVisible: false,
      QRVisible: false,
      visible: false, // 是否删除合同
      modalType: 0, // 0表示新增 1表示编辑
      fullscreenLoading: false, // 全局loading
      tableData: [],
      total: 0,
      loading: true,
      titleFinish: "",
      endNote: "",
      endData: {}, //
      queryFrom: {
        contractName: "",
        phone: "",
        pageNum: 1,
        pageSize: 10,
        clientName: "",
        deptName: "",
        deptId: "",
      },
      Contractinfo: {
        deptName: "",
        contractName: "",
        contractNo: "",
        clientName: "",
        phone: "",
        carNum: "",
        useDay: "",
        useStartDate: "",
        useEndDate: "",
        dateOrAddress: "",
        pathDetail: "",
        note: "",
        price: "",
        prepay: "",
        payment: "",
        invoiceNote: "",
        otherPay: "",
        businessPhone: "",
        businessUsername: "",
      },
      rules: {
        deptName: [{ required: true, message: "合同主题不能为空" }],

        signTime: [
          { required: true, message: "合同主题不能为空", trigger: "change" },
        ],
        contractName: [{ required: true, message: "合同主题不能为空" }],
        contractNo: [{ required: true, message: "合同编号不能为空" }],
        clientName: [{ required: true, message: "客户名称不能为空" }],
        phone: [{ required: true, message: "联系方式不能为空" }],
        carNum: [{ required: true, message: "车型及数量不能为空" }],
        useDay: [{ required: true, message: "用车天数不能为空" }],
        useStartDate: [{ required: true, message: "请选择日期" }],
        useEndDate: [{ required: true, message: "请选择日期" }],
        dateOrAddress: [{ required: true, message: "接人时间地点不能为空" }],
        pathDetail: [{ required: true, message: "路程详情不能为空" }],
        price: [{ required: true, message: "请输入包车运营费" }],
        otherPay: [{ required: true, message: "请选择费用承担方" }],

        businessPhone: [{ required: true, message: "请输入我司联系方式" }],
        businessUsername: [{ required: true, message: "请输入我司联系姓名" }],
      },
    };
  },
  components: {},
  methods: {
    // 下一页
    handleCurrentChange(val) {
      this.queryFrom.pageNum = val;
      this.contractList();
    },
    // 打开签约弹窗
    handleInitiate() {
      this.dialogVisible = true;
      this.modalType = 0;
      this.reset();
    },
    // 查看操作
    handleOperation(item) {
      this.modalType = 1;
      this.Contractinfo = JSON.parse(JSON.stringify(item));
      this.dialogVisible = true;
    },
    // 查看二维码
    QROperation(item) {
      this.QRVisible = true;
      setTimeout(() => {
        var qrCode = new QRCode(this.$refs.qrcode, {
          text:
            "https://permanentpicture.hellouxing.com/contract?id=" +
            item.id +
            "&phone=" +
            item.phone,
          width: "200",
          height: "200",
        });
      }, 200);
    },
    QRClose() {
      this.QRVisible = false;
    },

    // 关闭弹窗
    handleClose() {
      this.dialogVisible = false;
    },
    // 结束合同
    finishClose() {
      this.dialogFinish = false;
    },
    //发起签约
    handlecommit() {
      console.log(this.modalType);
      this.fullscreenLoading = true;
      if (this.modalType === 0) {
        this.contract();
      } else {
        this.modContract(this.Contractinfo);
      }
    },
    // 新增签约接口
    contract() {
      const deptId = this.Contractinfo.deptId;
      var deptName = "";
      if (deptId == null) {
        this.$message.error("请选择公司名称");
        return;
      }
      for (let index = 0; index < this.deptArr.length; index++) {
        const element = this.deptArr[index];
        if (element.deptId == deptId) {
          deptName = element.deptName;
          break;
        }
      }
      this.Contractinfo.deptName = deptName;
      this.$refs.Contractinfo.validate((valid) => {
        if (valid) {
          const data = this.Contractinfo;
          contract(data).then((data) => {
            const dict = data.data;
            if (dict.code === 200) {
              this.fullscreenLoading = false;
              this.$message.success("新增合同成功");
              this.dialogVisible = false;
              this.contractList();
            } else {
              this.fullscreenLoading = false;
              this.$message.error(dict.msg);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });

      this.fullscreenLoading = false;
    },

    // 查看修改合同
    modContract(data) {
      if (data.otherPay == "甲方") {
        data.otherPay = "1";
      }
      if (data.otherPay == "乙方") {
        data.otherPay = "2";
      }
      switch (data.status) {
        case "已发起":
          data.status = "0";
          break;
        case "已签约":
          data.status = "1";
          break;
        case "已作废":
          data.status = "2";
          break;
        case "已完成":
          data.status = "3";
          break;
        case "用户已阅览":
          data.status = "4";
          break;

        default:
          break;
      }
      console.log(data);
      modContract(data).then((data) => {
        const dict = data.data;
        console.log(dict);
        if (dict.code === 200) {
          this.fullscreenLoading = false;
          this.$message.success("修改成功");
          this.dialogVisible = false;
          this.contractList();
        } else {
          this.fullscreenLoading = false;
          this.$message.error(dict.msg);
        }
      });
    },

    // 作废合同
    cancellation(item, el) {
      this.endNote = "";
      this.dialogFinish = true;
      this.titleFinish = el == 0 ? "作废合同" : "完成服务";
      this.endData = item;
    },
    endSubmit() {
      this.dialogFinish = false;
      const data = {
        status: this.titleFinish == "作废合同" ? 2 : 3,
        id: this.endData.id,
        endNote: this.titleFinish + ":" + this.endNote,
      };
      this.modContract(data);
    },
    //  合同列表
    contractList() {
      this.loading = true;
      const data = this.queryFrom;
      contractList(data).then((data) => {
        const dict = data.data;
        console.log(dict);
        this.total = dict.total;
        if (dict.code === 200) {
          this.loading = false;
          console.log(dict);
          dict.rows.map((item) => {
            if (item.otherPay == "1") {
              item.otherPay = "甲方";
            } else {
              item.otherPay = "乙方";
            }
            switch (item.status) {
              case 0:
                item.status = "已发起";
                item.statusColor = "#666666";
                break;
              case 1:
                item.status = "已签约";
                item.statusColor = "#00B22E";
                break;
              case 2:
                item.status = "已作废";
                item.statusColor = "#FF0000";
                break;
              case 3:
                item.status = "已完成";
                item.statusColor = "#00B22E";
                break;
              case 4:
                item.status = "用户已阅览";
                item.statusColor = "#F58B4D";
                break;

              default:
                break;
            }
          });
          this.tableData = dict.rows;
        } else if (dict.code === 401) {
          this.$message.error("登录过期");
          Cokie.remove("roles");
          Cokie.remove("token");
          this.$router.push("/src/views/login.vue");
        } else {
          this.loading = false;
          console.log(dict);
          this.$message.error(dict.msg);
        }
      });
    },
    // 下载合同
    handleDownload(item) {
      const data = { id: item.id };
      axios.defaults.headers.common["Authorization"] = `Bearer ${Cokie.get(
        "token"
      )} `;
      axios({
        method: "get",
        url: "https://contractmanagerapi.hellouxing.com/system/contract/getContractPdf",
        // url:'http://192.168.12.128:9505/system/contract/getContractPdf',
        params: data,
        responseType: "blob",
      })
        .then((res) => {
          console.log(res.data);
          const blob = new Blob([res.data], { type: "application/pdf" });
          let objectURL = URL.createObjectURL(blob);
          let downEle = document.createElement("a");
          let fname = item.contractName;
          downEle.href = objectURL;
          const date = new Date();
          const formattedDate = this.formatDate(date);
          // console.log(beijingTime);
          downEle.setAttribute("download", "包车用车合同" + formattedDate);
          console.log("======fname");
          console.log(fname);
          console.log("======fname");

          document.body.appendChild(downEle);
          downEle.click();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //  table 样式
    headerCellStyle() {
      return "background-color: #e8e8e8;text-align: center;color: black;";
    },
    cellStyle() {
      return "text-align: center;";
    },

    // 表单重置
    reset() {
      this.Contractinfo = {
        deptName: "",
        contractName: undefined,
        contractNo: undefined,
        clientName: undefined,
        phone: undefined,
        carNum: undefined,
        useDay: undefined,
        useStartDate: undefined,
        useEndDate: undefined,
        dateOrAddress: undefined,
        pathDetail: undefined,
        note: undefined,
        price: undefined,
        prepay: undefined,
        payment: undefined,
        invoiceNote: undefined,
        otherPay: undefined,
        businessPhone: undefined,
        businessUsername: undefined,
        status: "0",
      };
      this.resetForm("Contractinfo");
    },

    userDeptById() {
      userDeptById().then((response) => {
        this.form = response.data.data;
        this.deptArr = response.data.data;
      });
    },
    // 年月日
    formatDate(date) {
      const year = date.getFullYear();
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const day = ("0" + date.getDate()).slice(-2);
      const hours = ("0" + date.getHours()).slice(-2);
      const minutes = ("0" + date.getMinutes()).slice(-2);
      const seconds = ("0" + date.getSeconds()).slice(-2);
      return `${year}${month}${day}${hours}${minutes}${seconds}`;
    },
  },
  mounted() {
    this.contractList();
    this.userDeptById();
  },
};
</script>

<style lang="less" scoped>
.page-view {
  background-color: white;

  .input-view {
    width: 10vw;
  }

  .input-view-plus {
    width: 15vw;
  }
}
</style>
