<template>
  <div class="page-view">
    <div class="login-view">
        <img src="../assets/login_logo.jpg" class="logo-view">
        
      <el-input v-model="LoginInfo.username" placeholder="账号" class="input-view" style="margin-top: 80px;"></el-input>
      <el-input v-model="LoginInfo.password" placeholder="密码" class="input-view"></el-input>
      <div style="display: flex; align-items: end;">
        <el-input v-model="LoginInfo.code" placeholder="验证码" class="input-view-mini"></el-input>
        <img :src="codeUrl" class="code-view" @click="GetCaptchaImage">

      </div>
      <div class="login-btn" @click="submit"  >登录</div>
    </div>
  </div>
</template>

<script>
import { captchaImage,login } from "../api/index";
import Cokie from 'js-cookie'
export default {

  data() {
    return {
      codeUrl: '',
      
      uuid:'',
      LoginInfo: {
        username: '',
        password: '',
        code: ''

      },

    };
  },

  mounted() {
    this.GetCaptchaImage()
  },
  methods: {
    submit: function () {
      this.PostLogin()
    },
    // 获取验证码
    GetCaptchaImage: function () {
      captchaImage().then((data) => {
        const dict = data.data;
        if (dict.code === 200) {
          console.log(dict);
          this.codeUrl = "data:image/gif;base64," + dict.img;
          this.uuid = dict.uuid;
        }
      });
    },
    // 登录接口
    PostLogin:function(){
      if(!this.LoginInfo.code){
        this.$message.error('请输入验证码');
        return;
      }
      if(!this.LoginInfo.username){
        this.$message.error('请输入账号');
        return;
      }
      if(!this.LoginInfo.username){
        this.$message.error('请输入密码');
        return;
      }
      const loading = this.$loading({
          lock: true,
          text: '登录中。。。',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      const data = {
        'code':this.LoginInfo.code,
        'username':this.LoginInfo.username,
        'password':this.LoginInfo.password,
        'uuid':this.uuid
      }
      login(data).then((data)=>{
        const dict = data.data;
        console.log(dict);
        if(dict.code===200){
          loading.close();
          Cokie.set('token', dict.token)
          this.$router.push('/contract')
          window.location.reload();
        }else{
          this.GetCaptchaImage()
          loading.close();
          this.$message.error(dict.msg);
        }
      })
    }


  },
};
</script>

<style lang="less" scoped>
.page-view {
  width: 100vw;
  height: 100vh;
  background-color: #707ed5;
  display: flex;
  justify-content: center;
  align-items: center;

  .login-view {
    width: 482px;
    height: 386px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 20px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    border-radius: 20px;

    .logo-view {
      position: absolute;
      box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 20px 0px;
      width: 100px;
      height: 100px;
      left: auto;
      right: auto;
      top: -50px;
      background-color: white;
      border-radius: 50%;
    }

    .input-view {
      margin-top: 20px;
      width: 304px;
    }

    .input-view-mini {
      margin-top: 20px;
      width: 202px;
    }

    .code-view {
      width: 92px;
      height: 38px;
      margin-left: 10px;
    }
  }

  .login-btn {
    margin-top: 50px;
    height: 40px;
    width: 250px;
    border-style: none;
    border-color: unset;
    color: rgb(255, 255, 255);
    border-radius: 3px;
    font-size: 14px;
    padding: 0px;
    text-align: center;
    line-height: 40px;
    background: rgb(118, 133, 249);
  }
}
</style>