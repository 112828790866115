export default{
    state:{
        tabsList:[
            {
                path: "/",
                name: "contract",
                label: "我的合同",
                url: "contract/contract",
            },
        ]// 面包屑数据
    },
    mutations:{
        // 更新面包屑
        selectMenu(state,val){
            // 判断添加的数据是否为首页
            var isint = 0;
            if (val.name !=='contract') {
              const index =  state.tabsList.findIndex(item =>{ return item.name ===  val.name} )
            //   如果不存在
            if (index ===-1) {
                state.tabsList.push (val)
            }             
        }

        },
        //删除tag
        closeTag(state,item){
            const index  = state.tabsList.findIndex(val =>val.name ===item.name);
            state.tabsList.splice(index,1)
        }

    }
}