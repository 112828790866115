import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/Main.vue';
import contract from '../views/Contract.vue';
import user from '../views/User.vue';
import Login from '../views/login.vue';
import Company from '../views/CompanyManage.vue';
import Admin from '../views/AdminManage.vue';
// 重写push|replace方法
//先把VueRouter的push和replace方法保存一份
let originPush = VueRouter.prototype.push;
let originReplace = VueRouter.prototype.replace;
VueRouter.prototype.push = function (location, resolve, reject) {
    // 此函数上下文(this指向)为VueRouter的一个实例
    if (resolve && reject) {    //如果我们自己指定了成功/失败的回调，则自己传入
        originPush.call(this, location, resolve, reject)
        //若此时直接使用originPush()方法，则函数内的this指向window（内部代码将无法执行）。故应用call或apply方法修改this指向
    } else {    //如果我们没有指定成功/失败的回调，则自动帮我们生成，防止报错
        originPush.call(this, location, () => { }, () => { })
    }
}
 
// replace方法同理
VueRouter.prototype.replace = function (location, resolve, reject) {
  if (resolve && reject) {
      originReplace.call(this, location, resolve, reject)
  } else {
      originReplace.call(this, location, () => { }, () => { })
  }
}
//1：创建路由组件
Vue.use(VueRouter)
//2:映射路由
const routes = [
    //主路由
    {
        path:'/',
        component:Main,
        redirect:'/contract',// 重定向
        children:[
            { path: 'contract', name:'contract', component: contract },
            { path: 'user', name:'user',component: user },
            { path: 'Company',  name:'Company', component: Company },
            { path: 'Admin',  name:'Admin',component: Admin },
        ]
    },
    {
        path:'/login',
        name:'login',
        component:Login,
        meta:{
            title:"登录"
        }
    }

 
  ]
  //3:创建实例
  const router = new VueRouter({
    routes // (缩写) 相当于 routes: routes
  })
  // 对外暴露
  export default router
  

