<template>
  <div class="header-container">
    <div class="menu-view">
         <el-breadcrumb separator="-">
        <el-breadcrumb-item v-for="item  in tags" :key="item.path" :to="{ path: item.path }"  >{{ item.label }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="r-content">
      <div class="welcome-label">欢迎回来，管理员</div>
      <div class="acc-label">{{user}}</div>
      <img src="../assets/touxiang.png" class="header-image" />
      <div class="exit-btn" @click="exitAction">退出</div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {  getInfo } from "../api";
import Cokie from 'js-cookie'
export default {
    data(){
        return {
          user:Cokie.get('user')?Cokie.get('user'):'',
        }
    },
   computed:{
    ...mapState({
        tags:state => state.tab.tabsList
    }),
   
    
  },
  mounted() {
    if(!Cokie.get('user')){
      this.getInfo();
    }
    
  },
  methods:{
    exitAction:function(){
      this.$confirm('是否退出当前账号', "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          Cokie.remove("user");
          Cokie.remove("deptId");
          Cokie.remove("token");
          this.$router.push('/src/views/login.vue')
        })
        .catch(() => {});
    },
    getInfo(){
      getInfo().then((data) => {
        const dict = data.data;
        if (dict.code === 200) {
          this.user = dict.user.userName;
          Cokie.set('user', this.user)
        } else {
          this.$message.error(dict.msg);
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.header-container {
  background-color: white;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 30px;
  border-bottom: 1px solid #b0b3c6;
  .menu-view{

    /deep/.el-breadcrumb__item{
        .el-breadcrumb__inner{
            font-weight:normal ;
            &.is-link{
                color: #666;
            }
        }
        &:last-child{
            .el-breadcrumb__inner{
                color: black;
            }
        }

    }
  }

  .r-content {
    display: flex;
    align-items: center;
 

    .welcome-label {
      font-size: 13px;
    }

    .acc-label {
      margin-left: 5px;
      color: #8895ee;
      font-size: 15px;
    }
    .header-image {
      width: 35px;
      height: 35px;
      margin-left: 5px;
    }

    .exit-btn {
      font-size: 13px;
      margin-left: 5px;
      color: #bfbfbf;
    }
  }
}
</style>