<template>
    <div class="tabs">
        <el-tag v-for="(item,index) in tags" :key="item.label" :closable="item.name !== 'contract'"
            :effect="$route.name === item.name ? 'dark' : 'plain'" @click="changeMenu(item)" @close="hadleclose(item,index)" size="small">
            {{ item.label }}
        </el-tag>
    </div>
</template>

<script>
import router from '@/router';
import { mapState,mapMutations } from 'vuex'
export default {
    data() {
        return {

        };
    },

    mounted() {

    },
    methods: {
        ...mapMutations(['closeTag']),
        // 跳转页面
        changeMenu(item){
            this.$router.push({name:item.name})

        },
        // 删除
        hadleclose(item,index){
            this.closeTag(item)
           const length = this.tags.length;
           //删除之后的调整
           if(item.name !== this.$route.name){
            console.log('-----删除0');
            return
           }
           //   删除最后一项
           if(index ===length){
            this.$router.push({name:this.tags[index-1].name})
           }else{
            this.$router.push({name:this.tags[index].name})
           }
        }
    },
    computed: {
        ...mapState({
            tags: state => state.tab.tabsList
        })
    }
};
</script>
<style lang="less" scoped> 
.tabs{
    padding-top: 10px;
    padding-left: 5px;
    .el-tag{
        margin-left: 5px;
        cursor: pointer;
    }

} 

</style>