<template>
  <div class="page-view">
    <div style="display: flex; justify-content: space-between; height: 40px">
      <el-form ref="form" :model="formInline" :inline="true">
        <el-form-item label="联系电话">
          <el-input placeholder="联系电话" class="input-view" v-model="formInline.phone"></el-input>
        </el-form-item>
        <el-form-item label="联系人 ">
          <el-input placeholder="联系人" class="input-view" v-model="formInline.clientName"></el-input>
        </el-form-item>
        <el-form-item>
          
          <el-button type="primary" @click="myClientList" icon="el-icon-search">搜索</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="info" @click="exportToExcel" icon="el-icon-thumb">导出</el-button>
        </el-form-item>
      </el-form>

    </div>
    <el-card style="margin-top: 40px">
      <el-table :data="tableData" border style="width: 100%" max-height="500" :header-cell-style="headerCellStyle"
        :cell-style="cellStyle" v-loading="loading">
        <el-table-column prop="index" label="序号"> </el-table-column>
        <el-table-column prop="phone" label="联系电话"> </el-table-column>
        <el-table-column prop="clientName" label="联系人"> </el-table-column>
        <el-table-column prop="contractCount" label="签约次数"> </el-table-column>
        <el-table-column prop="lastOrderDate" label="最近一次签约"> </el-table-column>
      </el-table>
    </el-card>
    <el-pagination v-show="total > 0" background layout="prev, pager, next" :total="total" style="margin-top: 20px"
      @current-change="handleCurrentChange">
    </el-pagination>
  </div>
</template>

<script>
import { myClientList } from "../api";
import Cokie from 'js-cookie'
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
export default {
  data() {
    return {
      total: 0,
      dialogVisible: false,
      labelPosition: "right",
      loading: true,
      formInline: {
        phone: "",
        clientName: "",
        pageNum: 1,
        pageSize: 10,
      },
      tableData: [
      ],
    };
  },
  methods: {
    // 导出Excel
    exportToExcel() {
      const data = [
        ["序号", "联系人","联系电话",  "签约次数", "最近一次签约"],
      ];
      let values = this.tableData.flatMap(item => Object.values(item));
      data.push(values)

      // 将数据转换为工作表
      const worksheet = XLSX.utils.aoa_to_sheet(data);

      // 创建工作簿并添加工作表
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

      // 生成Excel文件
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

      // 使用blob和FileReader创建一个Blob URL
      const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
      const blobUrl = window.URL.createObjectURL(dataBlob);

      // 使用saveAs下载文件
      saveAs(dataBlob, 'export.xlsx');

      // 清理
      window.URL.revokeObjectURL(blobUrl);
    },
    // 下一页
    handleCurrentChange(val) {
      this.queryFrom.pageNum = val;
      this.myClientList()
    },
    myClientList() {
      this.loading = true;
      var data = this.formInline;
      myClientList(data).then((data) => {
        const dict = data.data;
        this.total = dict.total;
        if (dict.code === 200) {
          this.loading = false;
          let newKey = 'index'
          for (let i = 0; i < dict.rows.length; i++) {
            dict.rows[i] = { [newKey]: i + 1, ...dict.rows[i] };
          }
          this.tableData = dict.rows;
          console.log(dict);
        } else if (dict.code === 401) {
          this.loading = false;
          this.$message.error("登录过期");
          Cokie.remove("roles");
          Cokie.remove("token");
          this.$router.push('/src/views/login.vue')
        } else {
          this.loading = false;
          this.$message.error(dict.msg);
        }
      });
    },
    //  table 样式
    headerCellStyle() {
      return "background-color: #e8e8e8;text-align: center;color: black;";
    },
    cellStyle() {
      return "text-align: center;";
    },
  },

  mounted() {
    this.myClientList();
  },
};
</script>

<style lang="less" scoped>
.page-view {
  background-color: white;

  .input-view {
    width: 10vw;
  }
}
</style>